/*
 * Copyright (C) Omnitor AB - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Developed using Visual studio code
 * Written by Jonas Karlsson <info@omnitor.se>, October 2021
 */

import { LocalStorageKey, LocalStorageManager } from '../LocalStorageManager';
import { UserPermissionManager, PermissionLevel } from './UserPermissionManager';

type OnLoginChanged = (loginStatus: boolean) => void;

enum LoginStatus {
   LoggedIn = "loggedIn",
   LoggedOut = "loggedOut",
}

export class UserManager {
   private static userManager: UserManager;

   // Triggers every time the login status is changed.
   private onLoginChanged!: OnLoginChanged;

   private userPermissionManager!: UserPermissionManager;

   private loggedInUser = "";
   private token = "";
   private digiroomUrl = "";

   /**
    * Singleton.
    */
   public static ins(): UserManager {
      if (!UserManager.userManager) {
         UserManager.userManager = new UserManager();
      }

      return UserManager.userManager;
   }

   /**
    * Resets everything back to nothing by making a new instance of the singleton and clears local storage.
    */
   public ResetUser(): void {
      UserManager.userManager = new UserManager();
      UserManager.userManager.setLoginStatus(false);
      UserManager.userManager.setLoggedInUser("");
      UserManager.userManager.setUserPermissionManager(PermissionLevel.NormalUser);
      UserManager.userManager.setToken("");
      UserManager.userManager.setDigiroomUrl("");
   }

   /**
    * Get the login status.
    */
   public getIsLoggedIn(): boolean {
      return LocalStorageManager.getItem(LocalStorageKey.LoginStatus) == LoginStatus.LoggedIn;
   }

   /**
    * Stores the login status in local storage.
    */
   public setLoginStatus(loginStatus: boolean): void {
      if (this.getIsLoggedIn() == loginStatus)
         return;

      LocalStorageManager.setItem(LocalStorageKey.LoginStatus, loginStatus ? LoginStatus.LoggedIn : LoginStatus.LoggedOut);

      if (this.onLoginChanged)
         this.onLoginChanged(loginStatus);
   }

   /**
    * Get the email of the logged in user.
    */
   public getLoggedInUser(): string {
      return this.loggedInUser;
   }

   /**
    * Set the email of the logged in user.
    */
   public setLoggedInUser(loggedInUser: string): void {
      LocalStorageManager.setItem(LocalStorageKey.LoggedInUser, loggedInUser);
   }

   /**
    * Get userPermissionManager.
    */
   public getUserPermissionManager(): UserPermissionManager {
      return this.userPermissionManager;
   }

   /**
    * Set userPermissionManager.
    */
   public setUserPermissionManager(userType: PermissionLevel | string): void {
      this.userPermissionManager = new UserPermissionManager(userType);
      LocalStorageManager.setItem(LocalStorageKey.PermissionLevel, userType.toString());
   }

   /**
    * Get the token of the user.
    */
   public getToken(): string {
      return this.token;
   }

   /**
    * Set the token of the user.
    */
   public setToken(token: string): void {
      this.token = token;
      LocalStorageManager.setItem(LocalStorageKey.Token, token);
   }

   /**
    * Gets the digiroom url.
    */
   public getDigiRoomUrl(): string {
      return this.digiroomUrl;
   }

   /**
    * Sets the digiroom url.
    */
   public setDigiroomUrl(url: string):void {
      this.digiroomUrl = url;
      LocalStorageManager.setItem(LocalStorageKey.DigiRoomUrl, url);
   }

   /**
    * Set onLoginChanged().
    */
   public setOnLoggedInChanged(callback: OnLoginChanged): void {
      this.onLoginChanged = callback;
   }
}