/*
 * Copyright (C) Omnitor AB - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Developed using Visual studio code
 * Written by Jonas Karlsson <info@omnitor.se>, October 2021
 */
export enum LocalStorageKey {
   LoginStatus = "loginStatus",
   LoggedInUser = "loggedInUser",
   PermissionLevel = "permissionLevel",
   Token = "token",
   DigiRoomUrl = "digiroomUrl"
}

export class LocalStorageManager {

   /**
    * Set an item in local storage.
    * @param itemName The name of the item to store.
    * @param value The value to store.
    */
   public static setItem(itemName: LocalStorageKey, value: string): void {
      if (window.localStorage)
         window.localStorage.setItem(itemName, value);
      else
         console.error("No local storage found");
   }

   /**
    * Get an item from local storage.
    * @param itemName The name of the item to get.
    */
   public static getItem(itemName: LocalStorageKey): string | null {
      if (window.localStorage)
         return window.localStorage.getItem(itemName);

      console.error("No local storage found for key");
      return null;
   }
}