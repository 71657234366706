
import { LanguageHandler } from "@/handlers/LanguageHandler/LanguageHandler";
import { RequestManager } from "@/managers/RequestManager";
import { RequestLogout } from "@/managers/Requests/BackendRequests";
import { UserManager } from "@/managers/UserManager/UserManager";
import { Options, Vue } from "vue-class-component";

enum MenuType {
   LoggedInMenu,
   LoggedOutMenu
}

/**
 * Menu used in App.vue.
 */
export default class Menu extends Vue {
   private LH: LanguageHandler = LanguageHandler.ins().getL();
   MenuType: any = MenuType;

   private menuType: MenuType = MenuType.LoggedOutMenu;
   private mobileMenu!: HTMLDivElement;
   private hamburgerIcon!: HTMLDivElement;

   created(): void {
      this.menuType = UserManager.ins().getIsLoggedIn() ? MenuType.LoggedInMenu : MenuType.LoggedOutMenu;
   }

   mounted(): void {
      this.mobileMenu = this.$refs.mobileMenu as HTMLDivElement;
      this.hamburgerIcon = this.$refs.hamburgerIcon as HTMLDivElement;

      UserManager.ins().setOnLoggedInChanged((loginStatus: boolean) => {
         this.menuType = loginStatus ? MenuType.LoggedInMenu : MenuType.LoggedOutMenu;
      });
   }

   /**
    * Show or hide the menu in mobile mode
    */
   private toggleHamburgerMenu(): void {
      this.mobileMenu.style.display = this.mobileMenu.style.display == "block" ? "none" : "block";

      if (this.mobileMenu.style.display == "block") {
         this.hamburgerIcon.className += " rotated";
      } else {
         this.hamburgerIcon.className = this.hamburgerIcon.className.replace(" rotated", "");
      }
   }

   /**
    * Logs out the user by clearing the session in the server.
    */
   private logout(): void {
      RequestManager.sendRequest(new RequestLogout());
      UserManager.ins().setLoginStatus(false);
      this.mobileMenu.style.display = "none";
      this.hamburgerIcon.className = this.hamburgerIcon.className.replace(" rotated", "");

      this.$router.push("/");
   }
}
