
import { LocalStorageKey, LocalStorageManager } from "@/managers/LocalStorageManager";
import { RequestManager } from "@/managers/RequestManager";
import { RequestLoginStatus } from "@/managers/Requests/BackendRequests";
import { ResponseBase, ResponseLoginStatus, ResponseType } from "@/managers/Responses/Responses";
import { UserManager } from "@/managers/UserManager/UserManager";
import { UserPermission } from "@/managers/UserManager/UserPermissionManager";
import { Options, Vue } from "vue-class-component";

// Triggers after you receive a response from RequestLoginStatus.
export type OnUserPermissionSet = (hasUserPermission: boolean) => void;

/**
 * Anything that only specific user types should be allowed to see should extend from this class
 */
export default abstract class BaseUserUIAddon extends Vue {
   private hasUserPermission = false;
   protected onUserPermissionSet: OnUserPermissionSet = () => {
      //
   };

   mounted(): void {
      // Reset values in UserManager if you refresh the page.
      if (!LocalStorageManager.getItem(LocalStorageKey.PermissionLevel)) {
         RequestManager.sendRequest(new RequestLoginStatus()).then((response: ResponseBase) => {
            if (response.responseType == ResponseType.Error) {
               this.hasUserPermission = false;
               if (this.onUserPermissionSet) {
                  this.onUserPermissionSet(this.hasUserPermission);
               }
            } else if (response.responseType == ResponseType.LoginStatus) {
               const loginStatus = response as ResponseLoginStatus;

               UserManager.ins().setUserPermissionManager(loginStatus.permissionLevel);
               this.hasUserPermission = UserManager.ins().getUserPermissionManager().hasPermission(this.getUserPermission());
               if (this.onUserPermissionSet) {
                  this.onUserPermissionSet(this.hasUserPermission);
               }

               this.removeUnaccessableComponents();
            }
         });
      } else {
         UserManager.ins().setUserPermissionManager(String(LocalStorageManager.getItem(LocalStorageKey.PermissionLevel)));
         this.hasUserPermission = UserManager.ins().getUserPermissionManager().hasPermission(this.getUserPermission());
         this.removeUnaccessableComponents();
      }
   }

   protected getHasUserPermission(): boolean {
      return this.hasUserPermission;
   }

   /**
    * The components id that will be used to remove components that the user doesn't have access to.
    */
   protected abstract get componentId(): string;

   /**
    * Returns the permission that is required for using this component.
    */
   protected abstract getUserPermission(): UserPermission;

   /**
    * Remove components that users don't have permissions for.
    */
   private removeUnaccessableComponents(): void {
      const baseUserUIAddon = document.getElementById(this.componentId) as HTMLDivElement;

      if (baseUserUIAddon) {
         if (!this.hasUserPermission) {
            baseUserUIAddon.remove();
         } else {
            baseUserUIAddon.style.display = "block";
         }
      }
   }

   /**
    * Determines if the user has high enough permission level to use this component.
    */
   protected get userPermission(): boolean {
      return this.hasUserPermission;
   }
}
