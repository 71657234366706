
import { Options, Vue } from "vue-class-component";
import DateSelector from "./Room/DateSelector.vue";

/**
 * Inspired by https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_loader
 */
export default class Spinner extends Vue {
   mounted(): void {
      //
   }
}
