import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w3-center" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = {
  key: 2,
  class: "w3-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.LH['rooms']), 1)
    ]),
    (_ctx.createdRooms.length > 0)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: "w3-ul w3-margin-bottom created-rooms",
          "aria-label": _ctx.LH['rooms']
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.createdRooms, (room) => {
            return (_openBlock(), _createElementBlock("li", { key: room }, [
              _createVNode(_component_Accordion, { room: room }, null, 8, ["room"])
            ]))
          }), 128))
        ], 8, _hoisted_2))
      : (_ctx.hasLoadedList)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h3", {
              class: "w3-center",
              "aria-label": _ctx.LH['no-room']
            }, _toDisplayString(_ctx.LH['no-room']), 9, _hoisted_4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_Spinner)
          ]))
  ], 64))
}