/*
 * Copyright (C) Omnitor AB - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Developed using Visual studio code
 * Written by Simon Jakobsson <info@omnitor.se>, Jan 2022
 */

import { LocalStorageKey, LocalStorageManager } from "../LocalStorageManager";
import { UserManager } from "../UserManager/UserManager";
import { RequestBase, RequestMethod } from "./Requests";

export class DigiRoomRequests extends RequestBase {

    constructor(requestMethod: RequestMethod = RequestMethod.POST) {
        super(UserManager.ins().getDigiRoomUrl(), requestMethod);
        // Set the headers.
        this.headers.set("Content-type", "application/json");
        this.headers.set("Authorization", UserManager.ins().getToken());
        this.headers.set('Access-Control-Allow-Origin', 'https://digiroom.se');
        this.headers.set('Access-Control-Allow-Credentials', 'true');
    }
}

export class DigiRoomRoomRequest extends DigiRoomRequests {

    constructor(roomid: string, starttime: number, endtime: number, roomsettings: any) {
        super();

        // Set the action.
        this.data.action = "createRoom";

        // Set user input data.
        this.data.roomid = roomid;
        this.data.starttime = starttime;
        this.data.endtime = endtime;
        this.data.settings = roomsettings;
    }
}

export class DigiRoomRemoveRequest extends DigiRoomRequests {

    constructor(roomid: string) {
        super();

        // Set the action.
        this.data.action = "deleteRoom";

        // Set user input data.
        this.data.roomid = roomid;
    }
}

export class DigiRoomSettingsRequest extends DigiRoomRequests {
    constructor(roomid: string) {
        super(RequestMethod.GET);

        // Set the action.
        this.data.action = "getRoom";

        // Set user input data.
        this.data.roomid = roomid;
    }
}