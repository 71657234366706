export class DateHelper {
   /**
    * Returns a date in the format: YEAR-MONTH-DAY HOUR:MINUTE.
    * @param date The date to convert.
    */
   public static getPrettyDateFormat(date: Date): string {
      const year = date.getFullYear();

      let month: string = Number(date.getMonth() + 1).toString();
      month = month.length == 1 ? "0" + month : month;

      let day: string = date.getDate().toString();
      day = day.length == 1 ? "0" + day : day;

      let hour: string = date.getHours().toString();
      hour = hour.length == 1 ? "0" + hour : hour;

      let minute: string = date.getMinutes().toString();
      minute = minute.length == 1 ? "0" + minute : minute;

      return year + "-" + month + "-" + day + " " + hour + ":" + minute;
   }
}