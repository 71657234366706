import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import CreateRoom from '../views/CreateRoom.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import RoomsList from '../views/RoomsList.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/forgot',
    name: 'Forgot Password',
    component: ForgotPassword,
  },
  {
    path: '/reset',
    name: 'Reset Password',
    component: ResetPassword,
  },
  {
    path: '/create',
    name: 'Create',
    component: CreateRoom,
  },
  {
    path: '/list',
    name: 'Rooms List',
    component: RoomsList,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
