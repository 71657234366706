import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fe0b0ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "roomListHeader" }
const _hoisted_2 = { class: "accordion-title-topic" }
const _hoisted_3 = {
  ref: "icon",
  id: "icon",
  class: "fa fa-angle-down"
}
const _hoisted_4 = { class: "accordion-title-time" }
const _hoisted_5 = {
  key: 0,
  ref: "content",
  id: "accordionAnimation",
  class: "w3-container w3-margin-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoomListItem = _resolveComponent("RoomListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onTrigger())),
      ref: "accordion",
      class: "w3-button w3-block w3-light-grey w3-center"
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(this.room.topic), 1),
      _createElementVNode("i", _hoisted_3, null, 512),
      _createElementVNode("span", _hoisted_4, _toDisplayString(this.listHeaderTime), 1)
    ], 512),
    (_ctx.displayRoom)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_RoomListItem, { room: _ctx.room }, null, 8, ["room"])
        ], 512))
      : _createCommentVNode("", true)
  ]))
}