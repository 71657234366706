
// @ is an alias to /src

import { ResponseBase, ResponseError, ResponseLogin, ResponseType } from "@/managers/Responses/Responses";
import { UserManager } from "@/managers/UserManager/UserManager";
import { Options, Vue } from "vue-class-component";
import { RequestManager } from "../managers/RequestManager";
import { LanguageHandler } from '../handlers/LanguageHandler/LanguageHandler';
import { LocalStorageKey, LocalStorageManager } from "@/managers/LocalStorageManager";
import { RequestLogin } from "@/managers/Requests/BackendRequests";
@Options({})
export default class Home extends Vue {
   private LH: LanguageHandler = LanguageHandler.ins().getL();
   
   mounted(): void {
      (this.$refs.errorMessage as HTMLBodyElement).style.display = "none";
      if (UserManager.ins().getIsLoggedIn()) {
         this.$router.push("/create");
         return;
      }
   }

   /**
    * Tries to login by sending a request to the admin portal server.
    */
   login(): void {
      // Error field.
      const errorMessage = this.$refs.errorMessage as HTMLSpanElement;

      // Input values.
      const inputEmail = (this.$refs.inputEmail as HTMLInputElement).value;
      const inputPassword = (this.$refs.inputPassword as HTMLInputElement).value;

      // Evaluate errors.
      if (inputEmail == "" || inputPassword == "") {
         errorMessage.style.display = "block";
         errorMessage.innerText = (this.LH as any)["must-type-password-email"];

         // Focus the errorMessage in order for screen reader to read the message correctly.
         document.getElementById('errorMessage')!.focus();

         return;
      }

      // Create request.
      let requestLogin = new RequestLogin(inputEmail, inputPassword);

      RequestManager.sendRequest(requestLogin).then((response: ResponseBase) => {
         // Success.
         if (response.responseType ==  ResponseType.Login) {
            // Cast object.
            const responseLogin = response as ResponseLogin;

            // Set the user manager information.
            UserManager.ins().setLoginStatus(true);
            UserManager.ins().setLoggedInUser(responseLogin.loggedInUser);
            UserManager.ins().setUserPermissionManager(responseLogin.permissionLevel);
            UserManager.ins().setToken(responseLogin.token);
            UserManager.ins().setDigiroomUrl(responseLogin.digiroomUrl);

            // Change page.
            this.$router.push("/create");
         }
         // Failure.
         else if (response.responseType == ResponseType.Error) {
            // Cast object.
            const responseError = response as ResponseError;

            // Reset values.
            UserManager.ins().setLoginStatus(false);
            LocalStorageManager.setItem(LocalStorageKey.PermissionLevel, "0");
            
            // Display error message.
            errorMessage.style.display = "block";
            errorMessage.innerText = (this.LH as any)[responseError.message];

            // Focus the errorMessage in order for screen reader to read the message correctly.
            document.getElementById('errorMessage')!.focus();
         }
         // Unknown.
         else {
            errorMessage.style.display = "block";
            errorMessage.innerHTML = "TODO Unknown response";
         }
      });
   }
}
