
import { Room } from "@/database_objects/Room";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import RoomListItem from "./RoomsList/RoomListItem.vue";

@Options({
   components: {
      RoomListItem
   }
})

/**
 * inspired by https://www.w3schools.com/w3css/w3css_accordions.asp
 */
export default class Accordion extends Vue {
   @Prop({ required: true }) private readonly room!: Room;

   private content!: HTMLDivElement;
   private icon!: HTMLLIElement;
   private displayRoom = false;

   mounted(): void {
      this.content = this.$refs.content as HTMLDivElement;
      this.icon = this.$refs.icon as HTMLLIElement;
   }

   /**
    * Return the header time of a room.
    */
   private get listHeaderTime(): string {
      if (this.room.start_date) {
         var endTime = this.room.end_date.slice(-8);
         var oListHeaderTime = this.room.start_date.slice(0, -3) + "-" + endTime.slice(0, -3);
         return oListHeaderTime;
      }
      else
         return "Permanent Room";
   }

   /**
    * Show or hide the content inside the accordion
    */
   private onTrigger(): void {
      this.displayRoom = !this.displayRoom;
      
      if (this.displayRoom) {
         this.icon.className = this.icon.className.replace("down", "up");
      }
      else {
         this.icon.className = this.icon.className.replace("up", "down");
      }
   }
}
