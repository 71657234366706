/*
 * Copyright (C) Omnitor AB - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Developed using Visual studio code
 * Written by Johan Nygren <info@omnitor.se>, Feb 2020
 */


/**
 * language handler serves language files to be used by UI.
 */
 export class LanguageHandler {

    //Instance of Language handler
    private static instance: LanguageHandler;
    //Current selected Language.
    private currentLang: string;

    // Default language.
    public readonly defaultLanguage = "en";

    /**
     * Get instance of languageHandler
     */
    public static ins(): LanguageHandler {
        //If not initialized
        if (!LanguageHandler.instance) {
            LanguageHandler.instance = new LanguageHandler();
        }
        return LanguageHandler.instance;
    }

    /**
     * Private constructor 
     * Checks your local language and tries to get your language,
     * Fallback is english. 
     */
    private constructor() {
        let userLang: string = navigator.language || (navigator as any).userLanguage;
        userLang = userLang.split("-")[0].toLowerCase() as string;

        this.currentLang = userLang;
        console.log(this.currentLang);

        if (document.documentElement)
            document.documentElement.lang = this.currentLang;
    }


    /**
     * Checks current selected languages and return the language strings as an Object.
     */
    public getL(): any {
        let language: any = null;

        try {
            language = require("./lang/" + this.currentLang + "/string.js");
        } catch (error) {
            // Set default language
            this.currentLang = this.defaultLanguage;
            language = require("./lang/" + this.currentLang + "/string.js");
        }

        return language;
    }
}
