
// @ is an alias to /src

import { Options } from "vue-class-component";
import BaseLoginView from "./BaseLoginView.vue";
import AdvancedRoomSettings from "../components/UserUIAddons/Room/AdvancedSettings.vue";
import { RequestManager } from "@/managers/RequestManager";
import { UserManager } from "@/managers/UserManager/UserManager";
import { DigiRoomApiResponse, ResponseBase, ResponseCreateRoom, ResponseError, ResponseType } from "@/managers/Responses/Responses";
import DateSelector from "../components/Room/DateSelector.vue";
import { LanguageHandler } from "@/handlers/LanguageHandler/LanguageHandler";
import { RequestCreatePermalink, RequestCreateRoom } from "@/managers/Requests/BackendRequests";
import { DigiRoomRoomRequest } from "@/managers/Requests/DigiRoomRequests";

@Options({
   components: {
      BaseLoginView,
      AdvancedRoomSettings,
      DateSelector
   }
})
export default class CreateRoom extends BaseLoginView {
   private LH: LanguageHandler = LanguageHandler.ins().getL();
   private advancedRoomSettings!: AdvancedRoomSettings;

   private roomName!: string;
   private topic!: HTMLInputElement;
   private dateSelector!: DateSelector;

   created(): void {
      if (!UserManager.ins().getIsLoggedIn()) {
         this.$router.push("/");
      }
   }

   mounted(): void {
      this.advancedRoomSettings = this.$refs.advancedRoomSettings as AdvancedRoomSettings;
      this.topic = this.$refs.topic as HTMLInputElement;
      this.dateSelector = this.$refs.dateSelector as DateSelector;

      this.roomName = Math.random().toString(36).substr(2, 9);

      // Hide info message.
      (this.$refs.infoMessageField as HTMLBodyElement).style.display = "none";
   }

   /**
    * Creates a room by sending a request to the digiroom server.
    */
   createRoom(): void {
      // Fetch values.
      let roomid = this.roomName;
      let starttime = this.dateSelector.getStartTime();
      let starttimePretty = this.dateSelector.getPrettyStartTime() + ":00";
      let endtime = this.dateSelector.getEndTime();
      let endtimePretty = this.dateSelector.getPrettyEndTime() + ":00";
      let topic = this.topic.value;
      let roomSettings = this.advancedRoomSettings.getSettings();

      // Require topic.
      if (this.topic.value.replace(/\s/g,'').length == 0) {
         this.infoMessage((this.LH as any)["no-topic-warning"]);
         // Focus the infoMessageField in order for screen reader to read the message correctly.
         document.getElementById('message')!.focus();
         return;
      }

      // Create the requests.
      let digiroomCreateRoomRequest = new DigiRoomRoomRequest(roomid, starttime, endtime, roomSettings);
      let backendCreateRoomRequest = new RequestCreateRoom(roomid, starttimePretty, endtimePretty, topic);
      
      if (this.dateSelector.isPermalinkChecked()) {
         // Special create.
         backendCreateRoomRequest = new RequestCreatePermalink(roomid, topic);
      }
      else {
         // Check values for errors.
         if (!this.dateSelector.getEndTime() || 
            !this.dateSelector.getStartTime() || 
            (this.dateSelector.getEndTime() - this.dateSelector.getStartTime()) < (1000 * 60 * 15) ||
            this.dateSelector.getStartTime() < (new Date().getTime() - (1000 * 60 * 15))) {
            this.infoMessage((this.LH as any)["wrong-start-duration"]);
            return;
         }
      }

      // Make the requests.
      RequestManager.sendRequest(digiroomCreateRoomRequest).then((response: ResponseBase) => {
         // Evaluate first response.
         if (response.responseType == ResponseType.DigiRoomApiResponse && (response as DigiRoomApiResponse).result == "success") {
            // Make second request to the backend.
            RequestManager.sendRequest(backendCreateRoomRequest).then((response: ResponseBase) => {
               // Success.
               if (response.responseType == ResponseType.CreateRoom) {
                  this.$router.push("/list");
               }
               // Failure.
               else  if (response.responseType == ResponseType.Error) {
                  const responseError = response as ResponseError;
                  this.infoMessage((this.LH as any)[responseError.message]);
               }
               // Unknown.
               else {
                  this.infoMessage("TODO ERROR Unknown response");
               }
            });
         }
         else 
            // DigiRoom error.
            this.infoMessage("TODO ERROR");
      });
   }

   /**
    * Manage info message.
    * @param messageContent Content of the message.
    */
   private infoMessage(messageContent:string): void {
      // Message field.
      const message = this.$refs.message as HTMLParagraphElement;
      const messageField = this.$refs.infoMessageField as HTMLBodyElement;

      // Create and display message.
      message.innerText = messageContent;
      messageField.style.display = "block";
   }
}
