/*
 * Copyright (C) Omnitor AB - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Developed using Visual studio code
 * Written by Simon Jakobsson <info@omnitor.se>, Jan 2022
 */

import { ResponseBase, ResponseLoginStatus, ResponseType, ResponseError, ResponseLogin, ResponseCreateRoom, ResponseCreatedRooms, ResponseAuthenticationCode } from './Responses/Responses';
import { RequestBase } from './Requests/Requests';
import { DigiRoomRequests, DigiRoomSettingsRequest } from './Requests/DigiRoomRequests';


/**
 * Manages request messages that gets sent to the server.
 */
export class RequestManager {

   /**
    * Sends an http request to the server. Returns 
    * the type of response according to the request.
    * @param request Contains the request.
    */
   public static sendRequest(request: RequestBase): Promise<ResponseBase> {
      // Create request.
      const xhttp = new XMLHttpRequest();
      // Setup connection.
      const method = request.getRequestMethod();
      const url = request.getRequestUrl();
      xhttp.open(method, url, true);
      // Set headers.
      request.setHeaders(xhttp);

      // Logging to see request.
      const randomNumber = Math.floor(Math.random() * 10000);
      console.log("%cRequest: "+ randomNumber +"\r\nMethod: " + method + " url: " + url + " data: " + request.getData(), "color:blue;");

      // Return the promise.
      return new Promise<ResponseBase>((resolve, reject) => {
         xhttp.onreadystatechange = () => {
            if (xhttp.readyState == 4 && xhttp.status == 200) {
               const parsedResponse = JSON.parse(xhttp.responseText);
               // Fix for DigiRoom API calls.
               if (request instanceof DigiRoomRequests)
                  parsedResponse.responseType = RequestManager.fixResponseTypeForDigiRoomApiCalls(request);

               // Logging.
               console.log("%cResponse: "+ randomNumber +"\r\n" + xhttp.responseText, "color:green;");

               // Parse the response to resolve as response base object.
               resolve(parsedResponse as ResponseBase);
            } else if (xhttp.status >= 300) {
               reject();
            }
         };

         // Send the request with the data.
         xhttp.send(request.getData());
      });
   }

   /**
    * This function allows a request to be given the appropriate response type.
    * This must be done as the backend calls and DigiRoom API calls differs.
    * @param request The request to be checked for.
    * @returns the response type that should be added.
    */
   private static fixResponseTypeForDigiRoomApiCalls(request: DigiRoomRequests): ResponseType {
      if (request instanceof DigiRoomSettingsRequest) {
         return ResponseType.DigiRoomSettingsResponse;
      }
      // TODO Add different fixes for different api calls.
      return ResponseType.DigiRoomApiResponse;
   }
}