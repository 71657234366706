
import BaseUserUIAddon from "../BaseUserUIAddon.vue";
import { Options, Vue } from "vue-class-component";
import Accordion from "../../RoomListHeader.vue";
import { LanguageHandler } from "@/handlers/LanguageHandler/LanguageHandler";
import { UserPermission } from "@/managers/UserManager/UserPermissionManager";

@Options({
   components: {
      Accordion
   }
})

/**
 * Specific component for the CreateRoom view.
 */
export default class AdvancedRoomSettings extends BaseUserUIAddon {
   private LH: LanguageHandler = LanguageHandler.ins().getL();

   private shareScreen!: HTMLInputElement;
   private chat!: HTMLInputElement;
   private branding!: HTMLInputElement;
   private debug!: HTMLInputElement;
   private authentication!: HTMLInputElement;
   private multipart!: HTMLSelectElement;
   private ignoreFirst!: HTMLInputElement;

   created(): void {
      this.onUserPermissionSet = (hasUserPermission: boolean) => {
         console.log("permission set");
         this.debug.checked = hasUserPermission;
      };
   }

   mounted(): void {
      this.shareScreen = this.$refs.shareScreen as HTMLInputElement;
      this.chat = this.$refs.chat as HTMLInputElement;
      this.branding = this.$refs.branding as HTMLInputElement;
      this.debug = this.$refs.debug as HTMLInputElement;
      this.authentication = this.$refs.authentication as HTMLInputElement;
      this.multipart = this.$refs.multipart as HTMLSelectElement;
      this.ignoreFirst = this.$refs.ignoreFirst as HTMLInputElement;

      if (this.getHasUserPermission()) {
         this.debug.checked = true;
      }
   }

   /**
    * Returns all the settings as an object of type any that will be sent to the digiroom server when creating a new room.
    */
   public getSettings(): any {
      const shareScreen = this.shareScreen ? this.shareScreen.checked : true;
      const chat = this.chat ? this.chat.checked : true;
      const debug = this.debug ? this.debug.checked : true;
      const authentication = this.authentication ? this.authentication.checked : false;
      const multipart = this.multipart ? Number(this.multipart.value) : 5;
      const ignoreFirst = this.ignoreFirst ? this.ignoreFirst.checked : false;

      const settings: any = {
         shareScreen: shareScreen,
         textChat: chat,
         debugging: debug,
         authenticate: authentication,
         ignoreFirst: ignoreFirst
      };

      if (multipart > 2) {
         settings.multipart = multipart;
      }

      if (this.branding.value) {
         settings.theme = JSON.parse(this.branding.value);
      }

      return settings;
   }

   protected getUserPermission(): UserPermission {
      return UserPermission.AdvancedRoomSettings;
   }

   protected get componentId(): string {
      return "advancedSettings";
   }
}
