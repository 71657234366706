/*
 * Copyright (C) Omnitor AB - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Developed using Visual studio code
 * Written by Simon Jakobsson <info@omnitor.se>, Jan 2022
 */

import { RequestBase, RequestMethod } from "./Requests";

export class BackendRequests extends RequestBase {

    // holds this servers url.
    private static readonly BACKEND_SERVER_URL = "/serve.php";

    constructor(action: string, requestMethod: RequestMethod = RequestMethod.POST) {
        super(BackendRequests.BACKEND_SERVER_URL, requestMethod);
        // Set the data action.
        this.data.action = action;
        // Set the headers.
        this.headers.set("Content-type", "application/json");
    }
}

export class RequestLoginStatus extends BackendRequests {
    public constructor() {
        super("loginStatus");
    }
}

export class RequestLogin extends BackendRequests {
    public readonly email!: string;
    public readonly password!: string;

    public constructor(email: string, password: string) {
        super("login");

        this.data.email = email;
        this.data.password = password;
    }
}

export class RequestLogout extends BackendRequests {
    public constructor() {
        super("logout");
    }
}

export class RequestAuthenticationCode extends BackendRequests {
    public readonly email!: string;

    /**
     * @param email The email to send to. Leave this empty in unit tests to avoid sending an email.
     */
    public constructor(email: string) {
        super("requestAuthenticationCode");
        this.data.email = email;
    }
}

export class RequestResetPassword extends BackendRequests {
    public readonly email!: string;
    public readonly password!: string;
    public readonly auth!: string;

    public constructor(email: string, password: string, auth: string) {
        super("resetPassword");

        this.data.email = email;
        this.data.password = password;
        this.data.auth = auth;
    }
}

export class RequestCreatedRooms extends BackendRequests {
    public constructor() {
       super("getCreatedRooms");
    }
}

export class RequestCreateRoom extends BackendRequests {
 
    public constructor(name: string, startTime: string, endTime: string, topic: string) {
       super("createRoom");
       
       this.data.name = name;
       this.data.startTime = startTime;
       this.data.endTime = endTime;
       this.data.topic = topic;
    }
}

export class RequestCreatePermalink extends BackendRequests {
 
    public constructor(name: string, topic: string) {
       super("createPermalink");
       
       this.data.name = name;
       this.data.topic = topic;
    }
}

export class RequestSendInvite extends BackendRequests {
    public readonly email!: string;
    public readonly phone!: string;
    public readonly roomid!: string;

    public constructor(email: string, phone: string, roomid: string) {
        super("sendInvite");

        this.data.email = email;
        this.data.phone = phone;
        this.data.roomid = roomid;

    }
}

export class RequestRemoveRoom extends BackendRequests {
    public constructor(name: string) {
       super("removeRoom");
       
       this.data.name = name;
    }
}