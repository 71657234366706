
import { LanguageHandler } from "@/handlers/LanguageHandler/LanguageHandler";
import { RequestManager } from "@/managers/RequestManager";
import { RequestAuthenticationCode } from "@/managers/Requests/BackendRequests";
import { ResponseAuthenticationCode, ResponseBase, ResponseError, ResponseType } from "@/managers/Responses/Responses";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class ForgotPassword extends Vue {
   private LH: LanguageHandler = LanguageHandler.ins().getL();

   mounted(): void {
      (this.$refs.errorMessage as HTMLBodyElement).style.display = "none";
   }

   /**
    * Sends an authentication code to the email that the user typed in so the user can change the password.
    */
   private requestAuthenticationCode(): void {
      // Fetch error field.
      const errorMessage = this.$refs.errorMessage as HTMLDivElement;

      // Fetch values.
      const inputEmail = (this.$refs.inputEmail as HTMLInputElement).value;

      // Create request.
      let requestAuthenticationCode = new RequestAuthenticationCode(inputEmail)

      // Make the request.
      RequestManager.sendRequest(requestAuthenticationCode).then((response: ResponseBase) => {
         // Success.
         if (response.responseType ==  ResponseType.AuthenticationCode) {
            this.$router.push({ name: 'Reset Password', params: { email: inputEmail }});
         }
         // Failure.
         else if (response.responseType ==  ResponseType.Error) {
            const responseError = response as ResponseError;
            errorMessage.style.display = "block";
            errorMessage.innerHTML = (this.LH as any)[responseError.message];
            // Focus the errorMessage in order for screen reader to read the message correctly.
            document.getElementById('errorMessage')!.focus();
         }
         // Unknown error. 
         else {
            errorMessage.style.display = "block";
            errorMessage.innerHTML = "TODO Unknown response";
         }
      });
   }
}
