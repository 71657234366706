
import { Options, Vue } from "vue-class-component";
import Menu from "./components/Menu.vue";
import packageJson from "./../package.json";

@Options({
   components: {
      Menu
   }
})
export default class App extends Vue {
   private version: string = packageJson.version;
   
   mounted(): void {
      // Fetch version
      console.log(this.version);
   }
}
