export enum UserPermission {
   AdvancedRoomSettings = "AdvancedRoomSettings",
   BrandingSettings = "BrandingSettings",
}

export enum PermissionLevel {
   NormalUser = "NormalUser",
   SuperUser = "SuperUser",
}

export class UserPermissionManager {
   private userPermissions: Map<PermissionLevel | string, Array<UserPermission>> = new Map();
   private userType!: PermissionLevel | string;

   constructor(userType: PermissionLevel | string) {
      this.userType = userType;

      this.userPermissions.set(PermissionLevel.NormalUser, []);
      this.userPermissions.set(PermissionLevel.SuperUser, [
         UserPermission.AdvancedRoomSettings,
         UserPermission.BrandingSettings,
      ]);
   }

   /**
    * Checks if the user has this permission.
    * @param userPermission The permission to check for
    */
   public hasPermission(userPermission: UserPermission): boolean {
      const permissionList = this.getUserPermissionList();
      return permissionList ? permissionList.includes(userPermission) : false;
   }

   /**
    * Get a list of all permissions.
    */
   public getUserPermissionList(): Array<UserPermission> {
      const userPermissionList = this.userPermissions.get(this.userType);
      return userPermissionList == undefined ? new Array<UserPermission>() : userPermissionList;
   }
}