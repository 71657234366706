
import { LanguageHandler } from "@/handlers/LanguageHandler/LanguageHandler";
import { RequestManager } from "@/managers/RequestManager";
import { RequestResetPassword } from "@/managers/Requests/BackendRequests";
import { ResponseBase, ResponseError, ResponseType } from "@/managers/Responses/Responses";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class ResetPassword extends Vue {
   private LH: LanguageHandler = LanguageHandler.ins().getL();

   /**
    * Sends a request to reset password in the database
    */
   private resetPassword(): void {
      // Fetch error field.
      const error = this.$refs.error as HTMLDivElement;

      // Fetch inputs.
      const inputEmail = this.$route.params.email as string;
      const inputPassword = (this.$refs.inputPassword as HTMLInputElement).value;
      const inputRepeatedPassword = (this.$refs.inputRepeatedPassword as HTMLInputElement).value;
      const inputAuth = (this.$refs.inputAuth as HTMLInputElement).value;

      // Evaluate password.
      if (inputPassword != inputRepeatedPassword) {
         error.innerHTML = (this.LH as any)["no-matching-passwords"];
         return;
      }

      // Make the request.
      let requestPasswordReset = new RequestResetPassword(inputEmail, inputPassword, inputAuth);

      RequestManager.sendRequest(requestPasswordReset).then((response: ResponseBase) => {
         // Failure.
         if (response.responseType == ResponseType.Error) {
            const responseError = response as ResponseError;
            error.innerHTML = (this.LH as any)[responseError.message];
         } 
         // Anything else counts as success. A bit wired.
         else {
            this.$router.push("/");
         }
      });
   }
}
