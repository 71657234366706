
import { LanguageHandler } from "@/handlers/LanguageHandler/LanguageHandler";
import { Options, Vue } from "vue-class-component";
import { DateHelper } from './DateHelper';

/**
 * Specific component for the CreateRoom view.
 */
export default class DateSelector extends Vue {
   private LH: LanguageHandler = LanguageHandler.ins().getL();
   private startTime!: HTMLInputElement;
   private durationHourSelector!: HTMLSelectElement;
   private durationMinuteSelector!: HTMLSelectElement;
   private permaLink!: HTMLInputElement;

   mounted(): void {
      this.startTime = this.$refs.startTime as HTMLInputElement;
      this.startTime.value = DateHelper.getPrettyDateFormat(new Date(Date.now())).replace(" ", "T");

      this.durationHourSelector = this.$refs.durationHourSelector as HTMLSelectElement;
      this.durationMinuteSelector = this.$refs.durationMinuteSelector as HTMLSelectElement;
   }

   /**
    * Gets the start time value in milliseconds.
    * This is used when sending a request to the digiroom server to create a room.
    */
   public getStartTime(): number {
      return new Date(this.startTime.value).getTime();
   }

   /**
    * Gets the start time as a string with the actual date format.
    * This is used when sending a request to the AdminPortal server to add the room to the database.
    */
   public getPrettyStartTime(): string {
      const date = new Date(Number(this.getStartTime()));
      return DateHelper.getPrettyDateFormat(date);
   }

   /**
    * Gets the end time value in milliseconds based on the start time value.
    * This is used when sending a request to the digiroom server to create a room.
    */
   public getEndTime(): number {
      let hoursInMilliSeconds = 1000 * 60 * 60 * Number(this.durationHourSelector.value);
      let minutesInMilliseconds = 1000 * 60 * Number(this.durationMinuteSelector.value);

      return this.getStartTime() + hoursInMilliSeconds + minutesInMilliseconds;
   }

   /**
    * Gets the start time as a string with the actual date format.
    * This is used when sending a request to the AdminPortal server to add the room to the database.
    */
   public getPrettyEndTime(): string {
      const date = new Date(Number(this.getEndTime()));
      return DateHelper.getPrettyDateFormat(date);
   }

   /**
    * Returns true if permalink is
    * checked.
    * @returns true if permalink is checked.
    */
   public isPermalinkChecked(): boolean {
      this.permaLink = this.$refs.permaLink as HTMLInputElement;
      return this.permaLink.checked;
   }

   /**
    * Permalink checkbox handler.
    */
   public permaLinkCheckBox(): void {
      this.permaLink = this.$refs.permaLink as HTMLInputElement;
      this.startTime.disabled = this.permaLink.checked;
      this.durationHourSelector.disabled = this.permaLink.checked;
      this.durationMinuteSelector.disabled = this.permaLink.checked;
   }
}

