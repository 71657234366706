
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import copy from "copy-to-clipboard";
import { Room } from "../../database_objects/Room";
import { LanguageHandler } from "@/handlers/LanguageHandler/LanguageHandler";
import { RequestManager } from "@/managers/RequestManager";
import { RequestRemoveRoom, RequestSendInvite } from "@/managers/Requests/BackendRequests";
import { DigiRoomRemoveRequest, DigiRoomSettingsRequest } from "@/managers/Requests/DigiRoomRequests";
import { DigiRoomSettingsResponse, ResponseBase, ResponseError, ResponseType } from "@/managers/Responses/Responses";

/**
 * Specific component for the RoomsList view.
 */
export default class RoomListItem extends Vue {
   @Prop({ required: true }) private readonly room!: Room;
   private LH: LanguageHandler = LanguageHandler.ins().getL();
   private showAuth = false;

   mounted(): void {
      this.loadRoomSettings();
   }

   private async loadRoomSettings() :Promise<void> {
      var request = new DigiRoomSettingsRequest(this.room.name);
      var response = await RequestManager.sendRequest(request) as DigiRoomSettingsResponse;
      this.showAuth = response.settings.authenticate;
   }

   /**
    * Returns the absolute path
    * to a room link.
    */
   private absoluteRoomLink(): string {
      var link = document.createElement("a");
      link.href = this.room.url;
      return link.protocol+"//"+link.host+link.pathname+link.search+link.hash;
   }

   /**
    * Add room url to clipboard.
    */
   private copyLink(): void {     
      copy(this.absoluteRoomLink());
   }

   private textChange(): void {
      const inputEmail = (this.$refs.inputEmail as HTMLInputElement).value;
      const inputPhone = (this.$refs.inputPhone as HTMLInputElement).value;
      const inviteBtn = this.$refs.inviteBtn as HTMLButtonElement;
      if (inputEmail.length + inputPhone.length > 0)
         inviteBtn.disabled = false;
      else
         inviteBtn.disabled = true;
   }

   /**
    * Send an invitation.
    */
   private sendInvite(): void {
      // Input values.
      const inputEmail = (this.$refs.inputEmail as HTMLInputElement).value;
      var inputPhone = (this.$refs.inputPhone as HTMLInputElement).value;

      // Handle phone number starting with 07.
      // TODO Handle non-Swedish phone numbers.
      if(/^07(0|2|3|6|9)\d{7}$/g.test(inputPhone)) {
         inputPhone = inputPhone.replace(/^.{1}/g, '+46');
      }

      // Validate values.
      let validEmail = (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(inputEmail));
      let validPhone = (/^\+467(0|2|3|6|9)\d{7}$/g.test(inputPhone));
      
      // Send e-mail.
      if (inputEmail.length > 0) {
         if (validEmail) {
            // Do not send if phone is entered and invalid.
            if (validPhone && inputPhone.length > 0 || inputPhone.length == 0) {
               // Validate response from server.
               RequestManager.sendRequest(new RequestSendInvite(inputEmail, "", this.room.name)).then((response: ResponseBase) => {
                  if (response.responseType == ResponseType.Error) {
                     let errorResponse = (response as ResponseError);
                     this.infoMessage(errorResponse.message, "w3-pale-red");     
                  }
                  else{
                     this.infoMessage((this.LH as any)["invite-success"], "w3-pale-green");
                     this.resetFields();
                  }
               }).catch(error=>{
                  this.infoMessage(error, "w3-pale-red");
               });
            }
            else
               this.infoMessage((this.LH as any)["invite-sms-fail"], "w3-pale-red");
         }
         else
            this.infoMessage((this.LH as any)["invite-email-fail"], "w3-pale-red");
      }
      
      // Send SMS.
      if (inputPhone.length > 0){
         if (validPhone) {
            // Do not send if email is entered and invalid.
            if (validEmail && inputEmail.length > 0 || inputEmail.length == 0) {
               // Validate response from server.
               RequestManager.sendRequest(new RequestSendInvite("", inputPhone, this.room.name)).then((response: ResponseBase) => {
                  if (response.responseType == ResponseType.Error) {
                     let errorResponse = (response as ResponseError);
                     this.infoMessage(errorResponse.message, "w3-pale-red");           
                  }
                  else {
                     this.infoMessage((this.LH as any)["invite-success"], "w3-pale-green");
                     this.resetFields();
                  }
               }).catch(error=>{
                  this.infoMessage(error, "w3-pale-red");
               });
            }
            else 
               this.infoMessage((this.LH as any)["invite-email-fail"], "w3-pale-red");
         }
         else
            this.infoMessage((this.LH as any)["invite-sms-fail"], "w3-pale-red");
      }

      // Focus the infoMessageField in order for screen reader to read the message correctly.
      document.getElementById('message')!.focus();
      
      // Both email and SMS sent.
      if (validEmail && validPhone)
         this.infoMessage((this.LH as any)["invite-success"], "w3-pale-green");
      else if (inputEmail.length > 0 && inputPhone.length > 0 && !validEmail && !validPhone)
         this.infoMessage((this.LH as any)["invite-email-sms-fail"], "w3-pale-red");

      return;
   }
   /**
    * Remove room from list and database.
    */
   private removeRoom(): void {
      // Remove from DigiRoom and Portal databases.
      RequestManager.sendRequest(new RequestRemoveRoom(this.room.name));
      RequestManager.sendRequest(new DigiRoomRemoveRequest(this.room.name));

      // Remove from list with animation.
      const selfAsElement = (this.$el as HTMLDivElement);
      selfAsElement.classList.add("fade");
      const rootContentNode = selfAsElement.parentNode?.parentNode?.parentNode?.parentNode as HTMLDivElement;
      const nodeToBeDeleted = selfAsElement.parentNode?.parentNode?.parentNode as HTMLDivElement;
      setTimeout(() =>{
         rootContentNode.removeChild(nodeToBeDeleted);
      }, 550);
   }

   /**
    * Manage info message.
    * @param messageContent Content of the message.
    * @param messageColor Color of the message text.
    */
   private infoMessage(messageContent:string, messageColor:string): void {
      // Message field.
      const message = this.$refs.message as HTMLParagraphElement;
      const messageField = this.$refs.infoMessageField as HTMLBodyElement;

      // Create and display message.
      message.innerText = messageContent;
      messageField.style.display = "block";
      messageField.classList.remove("w3-pale-green");
      messageField.classList.remove("w3-pale-red");
      messageField.classList.add(messageColor);
   }

   /**
    * Reset invite fields.
    */
   public resetFields(): void {
      (this.$refs.inputEmail as HTMLInputElement).value = "";
      (this.$refs.inputPhone as HTMLInputElement).value = "";
      (this.$refs.inviteBtn as HTMLButtonElement).disabled = true;
   }
}
