
import { Options } from "vue-class-component";
import BaseLoginView from "./BaseLoginView.vue";
import RoomListItem from "../components/RoomsList/RoomListItem.vue";
import { RequestManager } from "@/managers/RequestManager";
import { ResponseBase, ResponseCreatedRooms, ResponseType } from "@/managers/Responses/Responses";
import { Room } from "../database_objects/Room";
import { LanguageHandler } from "@/handlers/LanguageHandler/LanguageHandler";
import Spinner from "../components/Spinner.vue";
import { RequestCreatedRooms } from "@/managers/Requests/BackendRequests";
import Accordion from "@/components/RoomListHeader.vue";

@Options({
   components: {
      Accordion,
      Spinner
   }
})
export default class RoomsList extends BaseLoginView {
   private LH: LanguageHandler = LanguageHandler.ins().getL();
   private createdRooms: Array<Room> = new Array<Room>();

   // Just to make the UI look better while loading the page.
   private hasLoadedList = false;

   created(): void {
      RequestManager.sendRequest(new RequestCreatedRooms()).then((response: ResponseBase) => {
         // Success.
         if (response.responseType == ResponseType.CreatedRooms) {
            const responseCreatedRooms = response as ResponseCreatedRooms;
            this.createdRooms = responseCreatedRooms.createdRooms;
         }
         // Failure.
         else if (response.responseType ==  ResponseType.Error) {
            // TODO, should perhaps redirect to login page.
         } 

         this.hasLoadedList = true;
      });
   }

   mounted(): void {
      //
   }
}
