/*
 * Copyright (C) Omnitor AB - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Developed using Visual studio code
 * Written by Simon Jakobsson <info@omnitor.se>, Jan 2021
 */

// Holds the different Methods for remote calls.
export enum RequestMethod {
   POST = "POST",
   GET = "GET",
   PUT = "PUT"
}

/**
 * A class used to represent the basic of
 * all remote requests done.
 */
export class RequestBase {

   protected requestMethod: RequestMethod;
   private requestUrl: string; 

   protected headers: Map<string, string>;
   protected data: any;

   /**
    * Creates a class for sending requests to remote server.
    * @param requestUrl The url where to send the request.
    * @param requestMethod The method to be Used (POST, GET, PUT)
    */
   public constructor(requestUrl: string | null, requestMethod: RequestMethod = RequestMethod.POST) {
      // Make sure URL is set.
      if (requestUrl == null)
         throw new Error("Url is null, this is invalid.");
      // Copy values.
      this.requestMethod = requestMethod;
      this.requestUrl = requestUrl;

      // Initialize other values.
      this.headers = new Map<string, string>();
      this.data = {};
   }

   /**
    * The url to send the request.
    * @returns the url to send the request.
    */
   public getRequestUrl(): string {
      if (this.requestMethod == RequestMethod.POST)
         return this.requestUrl;
      else {
         const queryString = Object.keys(this.data).map((key) => {    return encodeURIComponent(key) + '=' + encodeURIComponent(this.data[key])}).join('&');
         return this.requestUrl + "?" + queryString;
      }
       
   }

   /**
    * Returns the method of the request (POST, GET, PUT, etc)
    * @returns the method of the request.
    */
   public getRequestMethod(): RequestMethod {
      return this.requestMethod;
   }

   /**
    * Returns the data of the request.
    * @returns the data of the request.
    */
   public getData(): string {
      if (this.requestMethod == RequestMethod.POST) 
         return JSON.stringify(this.data);
      else {
         return "";
      }
   }

   /**
    * Allow headers to be set on the xhttp object (XMLHttpRequest).
    * @param xhttp the xhttp object that should have headers.
    */
   public setHeaders(xhttp: XMLHttpRequest): void {
      this.headers.forEach((value: string, key: string) => {
         xhttp.setRequestHeader(key, value);
      });
   }
}