
import { RequestManager } from "@/managers/RequestManager";
import { RequestLoginStatus } from "@/managers/Requests/BackendRequests";
import { ResponseBase, ResponseLoginStatus, ResponseType } from "@/managers/Responses/Responses";
import { UserManager } from "@/managers/UserManager/UserManager";
import { Options, Vue } from "vue-class-component";

// Triggers every time you successfully receive a response from RequestLoginStatus.
export type OnLoginStatusReceived = (data: ResponseLoginStatus) => void;

/**
 * Makes sure that you can't stay in specific views if you are not logged in.
 */
export default class BaseLoginView extends Vue {
   private onLoginStatusReceived!: OnLoginStatusReceived;

   beforeCreate(): void {
      RequestManager.sendRequest(new RequestLoginStatus()).then((response: ResponseBase) => {
         if (response.responseType == ResponseType.Error) {
            UserManager.ins().ResetUser();
         } else if (response.responseType == ResponseType.LoginStatus) {
            const loginStatus = response as ResponseLoginStatus;

            UserManager.ins().setLoginStatus(loginStatus.isLoggedIn);
            UserManager.ins().setLoggedInUser(loginStatus.loggedInUser);
            UserManager.ins().setUserPermissionManager(loginStatus.permissionLevel);
            UserManager.ins().setToken(loginStatus.token);
            UserManager.ins().setDigiroomUrl(loginStatus.digiroomUrl);

            if (this.onLoginStatusReceived) {
               this.onLoginStatusReceived(loginStatus);
            }
         }

         if (!UserManager.ins().getIsLoggedIn()) {
            this.$router.push("/");
         }
      });
   }

   protected setOnLoginStatusReceived(callback: OnLoginStatusReceived): void {
      this.onLoginStatusReceived = callback;
   }
}
