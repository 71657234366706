/*
 * Copyright (C) Omnitor AB - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Developed using Visual studio code
 * Written by Jonas Karlsson <info@omnitor.se>, October 2021
 */

import { Room } from "@/database_objects/Room";
import { PermissionLevel } from "../UserManager/UserPermissionManager";

export enum ResponseType {
   Login = "Login",
   LoginStatus = "LoginStatus",
   Error = "Error",
   Success = "Success",
   CreateRoom = "CreateRoom",
   CreatedRooms = "CreatedRooms",
   AuthenticationCode = "AuthenticationCode",
   DigiRoomApiResponse = "DigiRoomApiResponse",
   DigiRoomSettingsResponse = "DigiRoomSettingsResponse"
}

export class ResponseBase {
   public readonly responseType!: ResponseType;
   
   public constructor(responseType: ResponseType) {
      this.responseType = responseType;
   }
}

export class ResponseError extends ResponseBase {
   public readonly message!: string;

   public constructor(message: string) {
      super(ResponseType.Error);

      this.message = message;
   }
}

export class ResponseLogin extends ResponseBase {
   public readonly loggedInUser: string;
   public readonly permissionLevel: PermissionLevel;
   public readonly token: string;
   public readonly digiroomUrl: string;

   public constructor(loggedInUser: string, permissionLevel: PermissionLevel, token: string, digiroomUrl: string) {
      super(ResponseType.LoginStatus);
      
      this.loggedInUser = loggedInUser;
      this.permissionLevel = permissionLevel;
      this.token = token;
      this.digiroomUrl = digiroomUrl;
   }
}

export class ResponseLoginStatus extends ResponseBase {
   public readonly loggedInUser!: string;
   public readonly isLoggedIn!: boolean;
   public readonly permissionLevel!: PermissionLevel;
   public readonly token!: string;
   public readonly digiroomUrl: string;

   public constructor(loggedInUser: string, isLoggedIn: boolean, permissionLevel: PermissionLevel, token: string, digiroomUrl: string) {
      super(ResponseType.Login);
      
      this.loggedInUser = loggedInUser;
      this.isLoggedIn = isLoggedIn;
      this.permissionLevel = permissionLevel;
      this.token = token;
      this.digiroomUrl = digiroomUrl;
   }
}

export class ResponseCreateRoom extends ResponseBase {
   public readonly createdRoom!: Room;

   public constructor(createdRoom: Room) {
      super(ResponseType.CreateRoom);

      this.createdRoom = createdRoom;
   }
}

export class ResponseCreatedRooms extends ResponseBase {
   public readonly createdRooms!: Array<Room>;

   public constructor(createdRooms: Array<Room>) {
      super(ResponseType.CreatedRooms);

      this.createdRooms = createdRooms;
   }
}

export class ResponseAuthenticationCode extends ResponseBase {
   public readonly auth!: string;

   public constructor(auth: string) {
      super(ResponseType.AuthenticationCode);

      this.auth = auth;
   }
}

export class DigiRoomApiResponse extends ResponseBase {
   public readonly result!: string;

   public constructor(result: string) {
      super(ResponseType.DigiRoomApiResponse);

      this.result = result;
   }
}

export class DigiRoomSettingsResponse extends ResponseBase {
   public readonly settings!: {
      authenticate: boolean;
   };

   public constructor(authenticate: boolean) {
      super(ResponseType.DigiRoomApiResponse);
      
      this.settings.authenticate = authenticate;
   }
}